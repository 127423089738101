import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/cybernate/header"
import { Container, Row, Col } from "reactstrap"
import "../css/subscribe.css"

const SubscribeConfirmed = () => (
    <Layout header={Header} footerClassName="fourzerofour">
        <SEO title="Subscription Request" />
        <Container fluid className="subscribe main-section">
            <Row className="justify-content-center">
                <Col>
                    <h1>Thank you for subscribing!</h1>
                    <p>You have subscribed successfully.</p>
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default SubscribeConfirmed
